import React from 'react';

type VideoPlayerProps = React.VideoHTMLAttributes<HTMLVideoElement>;

export default function VideoPlayer(props: VideoPlayerProps) {
  const { children, ...videoProps } = props;
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const lastTimeRef = React.useRef(0);
  const recoveringRef = React.useRef(false);
  const checkInterval = 5000; // Check every 5 seconds

  React.useEffect(() => {
    let checkTimeout: number | null = null;

    const video = videoRef.current;
    if (!video) return;

    const handleTimeUpdate = () => {
      lastTimeRef.current = video.currentTime;
    };

    const handleError = () => {
      console.error('Video encountered an error, attempting to recover...');
      attemptRecovery();
    };

    const checkForHang = () => {
      if (
        !video.paused &&
        !video.ended &&
        video.currentTime === lastTimeRef.current
      ) {
        console.warn(
          'Video playback appears to be hanging, attempting to recover...',
        );
        attemptRecovery();
      }
      checkTimeout = window.setTimeout(checkForHang, checkInterval);
    };

    const attemptRecovery = () => {
      if (!video || recoveringRef.current) return;
      recoveringRef.current = true;
      // Try reloading the video
      const currentTime = video.currentTime;
      video.load();
      video.currentTime = currentTime;
      video
        .play()
        .catch((err) => {
          console.error('Failed to play video after reload:', err);
        })
        .finally(() => {
          recoveringRef.current = false;
        });
    };

    video.addEventListener('timeupdate', handleTimeUpdate);
    video.addEventListener('error', handleError);

    checkTimeout = window.setTimeout(checkForHang, checkInterval);

    return () => {
      if (video) {
        video.removeEventListener('timeupdate', handleTimeUpdate);
        video.removeEventListener('error', handleError);
      }
      if (checkTimeout !== null) {
        clearTimeout(checkTimeout);
      }
    };
  }, []);

  return (
    <video ref={videoRef} preload='auto' {...videoProps}>
      {children}
    </video>
  );
}
